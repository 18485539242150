import type { Socket } from 'socket.io-client'

import type {
  CurrentPlanQuery,
  DetailOrgQuery,
  MyOrgsQuery,
  OrgRole,
  PlansQuery,
  SearchCalendarQuery,
  SearchOAuthQuery,
  SystemRole,
  User,
} from '../../../context/gql/codegen'

export class ProfileStore {
  isInternalUser = false
  currentSubscription: any
  profile: User = {} as any
  plans: PlansQuery['plans'] = []
  currentOrg: DetailOrgQuery['detailOrg']
  myOrgs: MyOrgsQuery['myOrgs'] = []
  systemRole?: SystemRole | null
  orgRole?: OrgRole | null
  isAuthenticate?: boolean
  isInitialDataSuccess?: boolean = true
  currentPlan?: CurrentPlanQuery['currentPlan'] | null =
    {} as CurrentPlanQuery['currentPlan']
  isRecurrencePlan: boolean
  integratedCalendar: SearchCalendarQuery['searchCalendar']
  oAuthIntegrations: SearchOAuthQuery['searchOAuth']
  isSyncedFromGGCalendar: boolean
  socketInstance: Socket | null
  get permissions(): string[] {
    const sp = this.convertPermissionToArr(this.systemRole?.permissions as any)
    const op = this.convertPermissionToArr(this.orgRole?.permissions as any)
    return [...sp, ...op]
  }
  private convertPermissionToArr = (ps?: {
    [key: string]: boolean
  }): string[] => {
    const res: string[] = []
    if (!ps) {
      return res
    }
    for (const key in ps) {
      if (ps[key]) {
        res.push(key)
      }
    }
    return res
  }
  get isEnterprise(): boolean {
    return this.currentPlan?.plan === 'Enterprise'
  }
  get isProfessional(): boolean {
    return this.currentPlan?.plan === 'Professional'
  }
  get isTeamAdmin(): boolean {
    return this.orgRole?.name === 'Admin'
  }
}
