import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

import { ToastService } from '../../../components/widget/Toast'
import { reactServerOrigin } from '../../../config'
import { S } from '../../../context/store'
import { showError } from '../../../utils/showError'

export const downloadTranscriptHandler = async (
  resourceInInsightEngineId: string,
  name: string,
  format: 'txt' | 'docx' = 'docx',
  config?: AxiosRequestConfig<unknown>,
  headerConfig?: {
    [key: string]: string
  },
) => {
  try {
    const res = await axios({
      method: 'post',
      url: `${reactServerOrigin}/api/downloadTranscript`,
      data: { resourceId: resourceInInsightEngineId, format },
      responseType: 'blob',
      headers: {
        authorization: `Bearer ${S.shared.authToken}`,
        Accept:
          format === 'txt'
            ? 'text/plain'
            : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ...headerConfig,
      },
      ...config,
    })
    const url = window.URL.createObjectURL(new Blob([res.data]))
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = `${name}.${format}`
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    ToastService.success({ content: `Start downloading ${name}.${format}` })
  } catch (err: any) {
    showError('Failed to download file', err as Error)
    console.error(err)
    return err
  }
}
