import { observer } from 'mobx-react-lite'

import css from './LeftActions.module.scss'

import { reactDeploymentEnv } from '#HACK_FOR_RN_ONLY/src/config'

import { S } from '../../../context/store'
import { FullScreenButton } from './FullScreenButton'
import { SessionModeButtons } from './SessionModeButton'
import { ToggleRoleButton } from './ToggleRoleButton'
import { ToggleThemeButton } from './ToggleThemeButton'
import { TranscriptButton } from './TranscriptButton'

export const RoomLeftActions = observer(() => {
  const {
    isViewmodeParticipant,
    isViewmodeObserver,
    isViewmodeHost,
    openTranscriptView,
  } = S.webrtc
  const { mode } = S.insightEngine
  const { currentPlan } = S.profile
  return (
    <div
      style={{
        left: openTranscriptView && mode === 'Cursor' ? 416 : 18,
      }}
      className={css.LeftActionsWrapper}
    >
      {reactDeploymentEnv &&
        reactDeploymentEnv === 'dev' &&
        (isViewmodeObserver || isViewmodeHost) && (
          // currentPlan?.plan === 'Enterprise' && <SessionModeButtons />}
          <SessionModeButtons />
        )}
      {(isViewmodeObserver || isViewmodeHost) && <ToggleRoleButton />}
      <FullScreenButton />
      {reactDeploymentEnv && isViewmodeParticipant && <ToggleThemeButton />}
      {reactDeploymentEnv &&
        reactDeploymentEnv === 'dev' &&
        (isViewmodeObserver || isViewmodeHost) &&
        mode === 'Cursor' && (
          // currentPlan?.plan === 'Enterprise' && <TranscriptButton />}
          <TranscriptButton />
        )}
    </div>
  )
})
