import clsx from 'clsx'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { qs } from 'url-parse'

import css from './BottomPopoverSchedule.module.scss'

import { filterSmallPeers } from '#rn-shared/mediasoup/lib/filterSmallPeers'

import { Icon } from '../../../components/base/Icon'
import { Duration } from '../../../components/composites/Duration'
import { ModalService } from '../../../components/Modal/Modal'
import { ToastService } from '../../../components/widget/Toast'
import {
  buildMixerQueryObject,
  onStopRecord,
} from '../../../context/actions/mixerActions'
import { ModalOutStorage } from '../../../context/shared/components/ModalOutStorage'
import { S } from '../../../context/store'
import {
  amsMediaPrefix,
  getMediaPeerId,
  getNormalId,
  isScreenshareMedia,
} from '../../../context/store/studio/WebrtcStore'
import { isOutOfStorage } from '../../../utils/plan'
import { ScheduleSession } from '../../CRUSession/components/ScheduleSession'
import { isInputPeer } from '../utils/isInputPeer'
import { ModalDestination } from './ModalDestination'
import { ModalRecordVideos } from './ModalRecordVideos'

export const BottomPopoverSchedule = observer(
  ({ onClose }: { onClose?: () => void }) => {
    const {
      peers,
      role,
      isMultipleRecording,
      multipleRecordingStartedAt,
      plan,
      detail,
      getPeer,
      videosRecordSelected,
      multipleRecordings,
    } = S.webrtc
    const showingPeers = filterSmallPeers(peers[0].data.viewmode, peers)
    const outOfStorage = isOutOfStorage(
      Number(plan?.totalStorage || 0),
      Number(plan?.totalStorageUsed || 0),
    )
    const isEnterprise = plan?.plan === 'Enterprise'
    const recordHandler = () => {
      if (!detail) {
        return
      }
      if (isMultipleRecording) {
        const selectedRecords = videosRecordSelected.length
          ? videosRecordSelected.map(id => {
              const screenshare = isScreenshareMedia(id)
              const mediaPeerId = getMediaPeerId(id)
              const peer = getPeer(mediaPeerId)
              const input = S.webrtc.inputs.find(i => i.id === getNormalId(id))
              const isAmsMedia = id.includes(amsMediaPrefix)
              if (!peer && !input && !isAmsMedia) {
                return null as any
              }
              return buildMixerQueryObject(
                {
                  id: isInputPeer(id) ? getNormalId(id) : mediaPeerId,
                  userId: isInputPeer(id) ? undefined : peer?.data.userId,
                  isInput: isInputPeer(id),
                },
                screenshare,
              )
            })
          : multipleRecordings.map(o1 => qs.parse(o1.mixerQuery))
        console.log(selectedRecords)
        console.log(toJS(videosRecordSelected))
        onStopRecord(detail.id, selectedRecords)
        return
      }
      // if (outOfStorage && !isEnterprise) {
      if (outOfStorage) {
        ModalService.show(ModalOutStorage)
        return
      }
      onClose?.()
      ModalService.show(ModalRecordVideos)
    }
    useEffect(() => {
      if (isMultipleRecording && multipleRecordingStartedAt) {
        ToastService.success({ content: 'Record started.' })
      }
    }, [isMultipleRecording, multipleRecordingStartedAt])
    return (
      <div className={css.WrapperPopoverContent}>
        <div>
          <div>
            <div
              className={css.PopoverItem}
              onClick={() => {
                onClose?.()
                ModalService.show(
                  ModalDestination,
                  {},
                  { name: 'ModalDestination' },
                )
              }}
            >
              <Icon
                icon='icon_manager_dest'
                size={13}
                style={{ marginRight: 12 }}
              />
              Manage Destination
            </div>
            <div
              className={css.PopoverItem}
              onClick={() => {
                onClose?.()
                ModalService.show(ScheduleSession)
              }}
            >
              <Icon
                icon='icon_schedule_session'
                size={13}
                style={{ marginRight: 12 }}
              />
              Schedule Session
            </div>
            {showingPeers.length &&
              (role?.name === 'Observer' || role?.name === 'Host') && (
                <div
                  className={clsx(css.PopoverItem, {
                    [css.Recording]: isMultipleRecording,
                  })}
                  onClick={recordHandler}
                >
                  <Icon
                    icon='icon_menu_record'
                    size={13}
                    style={{ marginRight: 12 }}
                  />
                  {isMultipleRecording && multipleRecordingStartedAt ? (
                    <>
                      Stop record <Duration from={multipleRecordingStartedAt} />
                    </>
                  ) : (
                    'Record multiple videos'
                  )}
                </div>
              )}
          </div>
        </div>
      </div>
    )
  },
)
